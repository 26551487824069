<template>
  <a-layout style="min-height: 100vh">
    <a-layout-sider
      v-model:collapsed="collapsed"
      :style="{ color: token.colorPrimary, backgroundColor: '#fff' }"
      breakpoint="lg"
      collapsible
      width="200"
    >
      <div class="logo">
        <router-link to="/admin">
          <img
            v-if="collapsed"
            alt="megatronic"
            :src="faviconUrl"
          >
          <img
            v-if="!collapsed"
            alt="megatronic"
            :src="headerIconUrl"
          >
        </router-link>
      </div>
      <a-menu
        v-model:selected-keys="selectedKeys"
        mode="inline"
        @click="handleClick"
      >
        <a-menu-item
          v-for="menu in menus"
          :key="menu.url"
          @click="goto(menu.url)"
        >
          <component :is="menu.icon" />
          <span>{{ menu.title }}</span>
        </a-menu-item>
        <a-sub-menu
          v-if="false"
          key="sub1"
        >
          <template #title>
            <span>
              <user-outlined />
              <span>Sales</span>
            </span>
          </template>
          <a-menu-item
            key="3"
            @click="goto('/admin/stocks')"
          >
            Stocks
          </a-menu-item>
        </a-sub-menu>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0">
        <a-menu
          mode="horizontal"
          :style="{ lineHeight: '64px' }"
        >
          <a-menu-item key="1">
            <change-store :simple="true" />
          </a-menu-item>
        </a-menu>
      </a-layout-header>
      <a-layout-content style="margin: 0 16px">
        <a-breadcrumb style="margin: 16px 0">
          <a-breadcrumb-item>Megatronic</a-breadcrumb-item>
          <a-breadcrumb-item>{{ route.name }}</a-breadcrumb-item>
        </a-breadcrumb>
        <div :style="{ padding: '24px', background: '#fff', borderRadius: '16px' }">
          <router-view v-slot="{ Component }">
            <transition
              mode="out-in"
              name="fade"
            >
              <component :is="Component" />
            </transition>
          </router-view>
        </div>
      </a-layout-content>
      <a-layout-footer style="text-align: center">
        © 2015 - {{ new Date().getFullYear() }}, PT. Raja Elektronik Indonesia
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>
<script setup>
import { computed, onMounted, ref } from 'vue'
import { theme } from 'ant-design-vue'
import { useRoute, useRouter } from 'vue-router'
import {
  PieChartOutlined,
  UserOutlined,
  DollarOutlined,
  ShoppingOutlined,
  SendOutlined,
  HomeOutlined,
  AppstoreOutlined,
  SkinOutlined
} from '@ant-design/icons-vue'
import { useMainStore } from '@/stores/main-store'
import components from '@/data/components'
import { app, pages } from '@/config'

const {
  ChangeStore
} = components

const { useToken } = theme
const { token } = useToken()

const route = useRoute()
const router = useRouter()
const mainStore = useMainStore()

const collapsed = ref(false)
const selectedKeys = ref([route.path])
const menus = ref([
  {
    url: pages.home,
    title: app.routerName.home,
    icon: HomeOutlined
  },
  {
    url: pages.internal.home,
    title: app.routerName.internal.home,
    icon: PieChartOutlined
  },
  {
    url: 'https://trello.com/b/KwDCcGTY/pasang-ac',
    title: 'Trello Pasang AC',
    icon: AppstoreOutlined
  },
  {
    url: pages.internal.priceAdjustment,
    title: app.routerName.internal.priceAdjustment,
    icon: DollarOutlined
  },
  {
    url: pages.internal.stockSummary,
    title: app.routerName.internal.stockSummary,
    icon: SkinOutlined
  },
  {
    url: pages.internal.purchaseOrder,
    title: app.routerName.internal.purchaseOrder,
    icon: ShoppingOutlined
  },
  {
    url: pages.internal.storeOrder,
    title: app.routerName.internal.storeOrder,
    icon: SendOutlined
  }
])

const faviconUrl = computed(() => mainStore.getFaviconUrl)
const headerIconUrl = computed(() => mainStore.getHeaderIconUrl)

onMounted(() => {
  collapsed.value = true
  selectedKeys.value = [route.path]
})

const handleClick = (val) => {
}
const goto = (url) => {
  if (url.startsWith('http')) {
    window.open(url)
    return
  }
  router.push(url)
}
</script>
<style lang="scss" scoped>
.logo {
  padding: 16px;

  img {
    width: 100%;
    height: 100%;
  }
}

:deep(.ant-layout-sider-trigger) {
}
</style>
