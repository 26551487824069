import httpApi from '@/utils/http-api'
import { api } from '@/config'

export default {
  getStores: (body = {}) => {
    return httpApi.getDataViaApi({
      url: api.internal.stores
    })
  },
  postChangeStore: (body = {}) => {
    return httpApi.postDataViaApi({
      url: api.internal.changeStore,
      body
    })
  }
}
