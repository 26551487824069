import { app, pages } from '@/config'
import { serializeQueryParams } from '@/utils/search-filter'

const javascriptProtocolRegex = /^javascript:/i
const httpsProtocol = 'https://'

const urlFriendlyString = (str = '') => {
  return str.replace(/[^a-z0-9_]+/gi, '-')
    .replace(/^-|-$/g, '').toLowerCase()
}

const cleanUrl = (str = '') => {
  return str.replace(javascriptProtocolRegex, httpsProtocol)
}

const openCustomerServiceWhatsapp = (number = '', text = '') => {
  if (!number) return
  const url = `${app.whatsAppLink(number)}${text ? `?text=${encodeURIComponent(text)}` : ''}`
  window.open(url, '_blank')
}

const gotoSearch = (router, params) => {
  router.push(pages.search + serializeQueryParams(params))
}

const gotoProductList = (router, searchValue) => {
  router.push(pages.productList.replace(':search', searchValue))
}

export {
  urlFriendlyString,
  cleanUrl,
  openCustomerServiceWhatsapp,
  gotoSearch,
  gotoProductList
}
