import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { pages } from '@/config'
import authAPI from '@/api/auth-api'
import dashboardAPI from '@/api/dashboard-api'
import storeAPI from '@/api/store-api'
import domainConfigAPI from '@/api/domain-config-api'

const storeFallback = {
  'kreditelektronik.com': 'KREDITELEKTRONIK',
  'megatronicstore.com': 'MEGATRONIC',
  'rajaelektronik.com': 'RE',
  'rajaelektronik.net': 'RE',
  'rajabike.com': 'RAJABIKE'
}

export const useMainStore = defineStore('main-store', () => {
  // states (mutations)
  const setIsMobile = ref(false)
  const setCurrentUser = ref({ init: true })
  const setIsGettingCurrentUser = ref(false)
  const setDashboard = ref({})
  const setIpAddress = ref('')
  const setGeolocation = ref({})
  const setDomainConfigs = ref([])
  const setCurrentDomainConfig = ref({})

  // getters
  const getIsMobile = computed(() => setIsMobile.value)
  const getUserDetail = computed(() => setCurrentUser.value || {})
  const getIsLoggedIn = computed(() => !!setCurrentUser.value.id)
  const getStoreFallback = computed(() => storeFallback)
  const getStoreCode = computed(() => setCurrentUser.value.storeCode || getStoreFallback.value[window.location?.hostname] || getStoreFallback.value['megatronicstore.com'])
  const getUserRoles = computed(() => setCurrentUser.value.userRoles || [])
  const getIsAdmin = computed(() => getIsLoggedIn.value && getUserRoles.value.includes('ADMIN'))
  const getIsSupervisor = computed(() => getIsLoggedIn.value && getUserRoles.value.includes('SUPERVISOR'))
  const getIsCashier = computed(() => getIsLoggedIn.value && getUserRoles.value.includes('CASHIER'))
  const getIsSales = computed(() => getIsLoggedIn.value && getUserRoles.value.includes('SALES'))
  const getIsCanvasser = computed(() => getIsLoggedIn.value && getUserRoles.value.includes('CANVASSER'))
  const getIsCustomer = computed(() => !getUserRoles.value.length)
  const getDashboard = computed(() => setDashboard.value || {})
  const getIpAddress = computed(() => setIpAddress.value || '')
  const getGeolocation = computed(() => setGeolocation.value || {})
  const getDomainConfigs = computed(() => setDomainConfigs.value || [])
  const getDomainConfigOptions = computed(() => getDomainConfigs.value.length
    ? getDomainConfigs.value
      .map(store => ({
        value: store.hostName,
        label: store.hostName
      }))
    : []
  )
  const getCurrentDomainConfig = computed(() => setCurrentDomainConfig.value || {})
  const getCurrentPhoneNumber = computed(() => getCurrentDomainConfig.value.whatsappGroupId)
  const getFaviconUrl = computed(() => new URL(`/src/assets/images/preferences/${getStoreCode.value}/favicon.png`, import.meta.url).href)
  const getFaviconUrlByStoreCode = computed(() => (storeCode = null) => new URL(`/src/assets/images/preferences/${storeCode}/favicon.png`, import.meta.url).href)
  const getHeaderIconUrl = computed(() => new URL(`/src/assets/images/preferences/${getStoreCode.value}/header-icon.png`, import.meta.url).href)

  // actions
  const setMobileDeviceStatus = (data) => {
    setIsMobile.value = data
  }
  const validateCurrentUser = (data) => {
    setCurrentUser.value = { ...data }
    // document.cookie = `session_id=${setCurrentUser.value.session_id}; path=/`
  }
  const invalidateCurrentUser = () => {
    setCurrentUser.value = {}
    document.cookie = 'JSESSIONID=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
    document.cookie = 'SESSION=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
  }
  const getCurrentUser = (data) => {
    if (setIsGettingCurrentUser.value) return
    setIsGettingCurrentUser.value = true
    return authAPI.postCurrentUser(data)
      .then(response => {
        validateCurrentUser(response.body.data)
        return response
      })
      .catch(response => {
        invalidateCurrentUser()
        return response
      })
      .finally(() => {
        setIsGettingCurrentUser.value = false
      })
  }
  const checkAccount = (data) => {
    return authAPI.postCheckAccount(data)
  }
  const requestOtp = (data) => {
    return authAPI.postRequestOtp(data)
  }
  const changeStore = (data) => {
    return storeAPI.postChangeStore(data)
  }
  const login = (data) => {
    return authAPI.postLogin(data)
      .then(response => {
        validateCurrentUser(response.body.data)
        return response
      })
  }
  const logout = () => {
    return authAPI.postLogout()
      .then(response => {
        invalidateCurrentUser()
        window.location.assign(pages.home)
        return response
      })
  }
  const fetchDashboard = (data) => {
    return dashboardAPI.getDashboard(data)
      .then(response => {
        setDashboard.value = response.body.data
        return response
      })
  }
  const fetchIpAddress = (data) => {
    return authAPI.getIPAddress(data)
      .then(response => {
        setIpAddress.value = response.body.ip
        return response
      })
  }
  const fetchGeolocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const {
          latitude,
          longitude
        } = position?.coords || {}
        setGeolocation.value = {
          latitude,
          longitude,
          coordinate: `${latitude},${longitude}`,
          googleMaps: `https://www.google.com/maps/@${latitude},${longitude},15z`
        }
        return position
      })
    }
  }
  const fetchDomainConfigs = ({ data } = {}) => {
    return domainConfigAPI.getDomainConfigs(data)
      .then(response => {
        setDomainConfigs.value = response.body.data
        return response
      })
  }
  const fetchCurrentDomainConfig = ({ data } = {}) => {
    return domainConfigAPI.getCurrentDomainConfig(data)
      .then(response => {
        setCurrentDomainConfig.value = response.body.data
        return response
      })
  }

  return {
    // states
    setIsMobile,
    setCurrentUser,
    setIsGettingCurrentUser,
    setDashboard,
    setIpAddress,
    setGeolocation,
    setDomainConfigs,
    setCurrentDomainConfig,
    // getters
    getIsMobile,
    getUserDetail,
    getIsLoggedIn,
    getStoreCode,
    getIsAdmin,
    getIsSupervisor,
    getIsCashier,
    getIsSales,
    getIsCanvasser,
    getIsCustomer,
    getDashboard,
    getIpAddress,
    getGeolocation,
    getDomainConfigs,
    getDomainConfigOptions,
    getCurrentDomainConfig,
    getCurrentPhoneNumber,
    getFaviconUrl,
    getFaviconUrlByStoreCode,
    getHeaderIconUrl,
    // actions,
    setMobileDeviceStatus,
    checkAccount,
    requestOtp,
    changeStore,
    getCurrentUser,
    login,
    logout,
    fetchDashboard,
    fetchIpAddress,
    fetchGeolocation,
    fetchDomainConfigs,
    fetchCurrentDomainConfig
  }
})
