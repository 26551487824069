const updateQuery = (router, params, replace = true) => {
  const route = {
    query: JSON.parse(JSON.stringify(cleanParams(params)))
  }
  if (replace) {
    router.replace(route).catch(err => {
      console.log('replace', err)
    })
    return
  }
  router.push(route).catch(err => {
    console.log('push', err)
  })
}

const cleanParams = (params) => {
  const result = { ...params }
  Object.entries(result).forEach(([key, value]) => {
    if (isEmptyObject(value)) {
      delete result[key]
    }
  })
  return result
}

const isEmptyObject = object => {
  if (!object) {
    return true
  }
  if (typeof object !== 'object') {
    return !object || !(`${object}`)
  }
  return Object.values(object).every(x => x === null || x === '')
}

export {
  updateQuery,
  cleanParams
}
