export default {
  storePreferences: {
    '': {
    },
    MEGATRONIC: {
      colorPrimary: '#d33534'
    },
    KREDITELEKTRONIK: {
      colorPrimary: '#0078f4'
    },
    BE: {
      colorPrimary: '#005fea'
    },
    CE: {
      colorPrimary: '#2e3291'
    },
    DS: {
      colorPrimary: '#6d6e71'
    },
    IE: {
      colorPrimary: '#0037e8'
    },
    KE: {
      colorPrimary: '#ef9100'
    },
    KETV: {
      colorPrimary: '#e40713'
    },
    ME: {
      colorPrimary: '#0349af'
    },
    MG: {
      colorPrimary: '#ff0000'
    },
    RE: {
      colorPrimary: '#1d0e82'
    },
    RECK: {
      colorPrimary: '#1d0e82'
    },
    REKR: {
      colorPrimary: '#1d0e82'
    },
    REPK: {
      colorPrimary: '#1d0e82'
    },
    RETV: {
      colorPrimary: '#1d0e82'
    },
    SE: {
      colorPrimary: '#1600ff'
    },
    TE: {
      colorPrimary: '#1716f8'
    },
    VE: {
      colorPrimary: '#1766a3'
    }
  }
}
