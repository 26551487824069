import httpApi from '@/utils/http-api'
import { api } from '@/config'

export default {
  postLogin: (body = {}) => {
    return httpApi.postDataViaApi({
      url: api.login,
      body
    })
  },
  postLogout: (body = {}) => {
    return httpApi.postDataViaApi({
      url: api.logout,
      body
    })
  },
  postCheckAccount: (body = {}) => {
    return httpApi.postDataViaApi({
      url: api.checkAccount,
      body
    })
  },
  postRequestOtp: (body = {}) => {
    return httpApi.postDataViaApi({
      url: api.requestOtp,
      body
    })
  },
  postCurrentUser: (body = {}) => {
    return httpApi.postDataViaApi({
      url: api.user,
      body
    })
  },
  getIPAddress: (body = {}) => {
    return httpApi.getDataViaApi({
      url: api.thirdParty.ipify,
      body
    })
  }
}
