import httpApi from '@/utils/http-api'
import { api } from '@/config'

export default {
  getDomainConfigs: (body = {}) => {
    return httpApi.getDataViaApi({
      url: api.domainConfigs,
      body
    })
  },
  getCurrentDomainConfig: (body = {}) => {
    return httpApi.getDataViaApi({
      url: api.currentDomainConfig,
      body
    })
  }
}
