<template>
  <a-config-provider
    :theme="{
      token,
    }"
  >
    <a-app
      :class="['app', `store-preference-${storeCode}`]"
    >
      <router-view v-slot="{ Component }">
        <transition
          mode="out-in"
          name="fade"
        >
          <component :is="Component" />
        </transition>
      </router-view>
    </a-app>
  </a-config-provider>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useMainStore } from '@/stores/main-store'
import { useRouter } from 'vue-router'
import { app, pages } from '@/config'
import { isMobile } from '@/utils/responsive'

const router = useRouter()
const mainStore = useMainStore()

const token = ref({ ...app.storePreferences[''] })

const storeCode = computed(() => mainStore.getStoreCode)

onMounted(() => {
  mainStore.getCurrentUser()
  checkIsIframe()
  handleResize()
  setAppTheme()
  fetchIpAddress()
  fetchGeolocation()
})

const checkIsIframe = () => {
  const isIframe = window.self !== window.top
  if (isIframe) {
    router.push(pages.home)
  }
}
const handleResize = () => {
  setDeviceType()
  window.addEventListener('resize', setDeviceType)
}
const setDeviceType = () => {
  mainStore.setMobileDeviceStatus(isMobile())
}
const setAppTheme = () => {
  token.value = app.storePreferences[storeCode.value]
}
const fetchIpAddress = () => {
  mainStore.fetchIpAddress()
}
const fetchGeolocation = () => {
  mainStore.fetchGeolocation()
}
watch(() => storeCode.value, _ => {
  setAppTheme()
})
</script>

<style lang="scss" scoped>
.app {
  width: 100%;
}
</style>
