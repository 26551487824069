<template>
  <a-layout class="app-layout">
    <default-header />
    <a-layout-content class="app-layout__content content">
      <router-view />
    </a-layout-content>
    <default-footer />
    <default-bottom-navigation v-if="isHomepage" />
    <a-float-button
      v-if="!isMobile"
      shape="circle"
      @click="openCustomerServiceWhatsapp(whatsappNumber, `Hi!`)"
    >
      <template #icon>
        <WhatsAppOutlined />
      </template>
    </a-float-button>
  </a-layout>
</template>

<script setup>
import components from '@/data/components'
import { computed, onMounted } from 'vue'
import { app } from '@/config'
import { useRoute } from 'vue-router'
import { useCategoryStore } from '@/stores/category-store'
import { useMainStore } from '@/stores/main-store'
import { WhatsAppOutlined } from '@ant-design/icons-vue'
import { openCustomerServiceWhatsapp } from '@/utils/url'

const {
  DefaultHeader,
  DefaultFooter,
  DefaultBottomNavigation
} = components

const route = useRoute()
const mainStore = useMainStore()
const categoryStore = useCategoryStore()

const isHomepage = computed(() => route.name === app.routerName.home)
const domainConfig = computed(() => mainStore.getCurrentDomainConfig)
const whatsappNumber = computed(() => domainConfig.value.whatsappGroupId)
const isMobile = computed(() => mainStore.getIsMobile)

onMounted(() => {
  categoryStore.fetchCategories()
  mainStore.fetchCurrentDomainConfig()
})
</script>

<style lang="scss" scoped>
.app-layout {
  background: $blu-color-neutral-background-default;

  @include desktop {
    width: 100%;
    margin: 0 auto;
    padding-bottom: $bottom-height;
  }

  &__content {
    margin-top: $header-height;
    @include desktop {
      margin-top: 0;
      //padding-bottom: $bottom-height;
    }
  }
}

</style>
