import { useMainStore } from '@/stores/main-store'
import { pages } from '@/config'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

const timeout = 100
const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms))

const guardGlobal = async (to, from, next) => {
  next()
}

const guardAuth = async (to, from, next, { needNext } = {}) => {
  const location = window.location.pathname
  const mainStore = useMainStore()
  const isLoggedIn = computed(() => mainStore.getIsLoggedIn)

  if (mainStore.setCurrentUser.init) {
    await delay(timeout)
    return guardAuth(to, from, next)
  }

  if (!isLoggedIn.value) {
    window.location.assign(`${pages.login}?redirect=${location}`)
    return
  }

  if (!needNext) {
    return
  }

  next()
}

const guardAdmin = async (to, from, next) => {
  await guardAuth(to, from, next, { needNext: false })
  const location = window.location.pathname
  const mainStore = useMainStore()
  const isAdmin = computed(() => mainStore.getIsAdmin)
  const isSupervisor = computed(() => mainStore.getIsSupervisor)
  const isCashier = computed(() => mainStore.getIsCashier)
  if (!isAdmin.value && !isSupervisor.value && !isCashier.value) {
    window.location.assign(`${pages.notAuthorized}?redirect=${location}`)
    return
  }
  next()
}

const guardSales = async (to, from, next) => {
  await guardAuth(to, from, next, { needNext: false })
  const location = window.location.pathname
  const mainStore = useMainStore()
  const isAdmin = computed(() => mainStore.getIsAdmin)
  const isSupervisor = computed(() => mainStore.getIsSupervisor)
  const isCashier = computed(() => mainStore.getIsCashier)
  const isSales = computed(() => mainStore.getIsSales)
  if (!isAdmin.value && !isSupervisor.value && !isCashier.value && !isSales.value) {
    window.location.assign(`${pages.notAuthorized}?redirect=${location}`)
    return
  }
  next()
}

const guardGuest = async (to, from, next) => {
  const router = useRouter()
  const mainStore = useMainStore()
  const isLoggedIn = computed(() => mainStore.getIsLoggedIn)
  await delay(timeout)

  if (isLoggedIn.value) {
    router.push(pages.home)
    next(false)
    return
  }

  next()
}

export {
  guardGlobal,
  guardAuth,
  guardAdmin,
  guardSales,
  guardGuest
}
