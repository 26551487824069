import id from '@/i18n/locale/id.json'
import en from '@/i18n/locale/en.json'
import { createI18n } from 'vue-i18n'
import { app } from '@/config'

const translations = {
  en, id
}

const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem(app.localStorage.language) || import.meta.env.VITE_I18N_LOCALE || 'id',
  fallbackLocale: import.meta.env.VITE_I18N_FALLBACK_LOCALE || 'en',
  messages: translations
})
export default i18n
