import httpApi from '@/utils/http-api'
import { api } from '@/config'

export default {
  getDashboard: (body = {}) => {
    return httpApi.getDataViaApi({
      url: api.dashboard,
      body
    })
  },
  getInternalDashboard: (body = {}) => {
    return httpApi.getDataViaApi({
      url: api.internal.dashboard,
      body
    })
  }
}
