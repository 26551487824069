const MOBILE_BREAKPOINT = 840 - 1
const MOBILE_LANDSCAPE = 1024 - 1
const LANDSCAPE_PRIMARY = 'landscape-primary'

const isMobile = () => {
  const mql = window.matchMedia('(orientation: portrait)')
  const orientation = mql.matches ? 'portrait-primary' : 'landscape-primary'
  const { clientWidth } = window.document.documentElement

  if (clientWidth <= MOBILE_LANDSCAPE && orientation === LANDSCAPE_PRIMARY) {
    return true
  }
  return clientWidth <= MOBILE_BREAKPOINT
}

export {
  isMobile
}
